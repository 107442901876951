define('app/main',[
  "jquery",
  "app/carousel",
  "app/menu",
  "app/analytics",
  "app/searchFilters",
  "app/forms",
  "app/friendForm",
  "app/velsenaarForm",
  "app/ie9fix",
  "app/ie10fix",
  "app/bootstrapselect",
  "app/pagination",
  "app/basket",
  "app/calendar",
  "app/newsletterPopup",
], function (
  $,
  carousel,
  menu,
  analytics,
  searchFilters,
  forms,
  friendForm,
  velsenaarForm,
  ie9fix,
  ie10fix,
  bootstrapselect,
  pagination,
  basket,
  calendar,
  newsletterPopup
) {
  "use strict";
  $(document).ready(function () {
    console.log("Loaded app/main.js");
    carousel.init();
    menu.init();
    analytics.init();
    searchFilters.init();
    forms.init();
    friendForm.init();
    velsenaarForm.init();
    ie9fix.init();
    ie10fix.init();
    bootstrapselect.init();
    pagination.init();
    basket.init();
    calendar.init();
    newsletterPopup.init();
  });
});

