define('app/newsletterPopup',["jquery"], function ($) {
  "use strict";

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  return {
    init: function () {
      const popupCookie = getCookie("newsletter_popup_shown");

      if (!popupCookie) {
        $("#header-newsletter-subscribe-section").fadeIn().show();
      }

      $("#header-newsletter-close-button").on("click", function () {
        document.cookie = `newsletter_popup_shown=true; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT;`;
        $("#header-newsletter-subscribe-section").hide();
      });

      $("#header-newsletter-subscribe-link").on("click", function () {
        document.cookie = `newsletter_popup_shown=true; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT;`;
      });
    },
  };
});

